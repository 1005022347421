import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { ref } from "vue";

const selectedPage = ref(1);

export function useHomeFilterCategory() {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const DEFAULT_PAGE_SIZE = 9;
  function changePage(newPage: number) {
    selectedPage.value = newPage;
    const {category, label} = getSelectedCategoryOnURL();
    doFilter([category, label]);
  }
  function setNewFilter(category: string[]){
    selectedPage.value = 1;
    doFilter(category);
  }
  function doFilter(category: Array<string>) {
    // add to the end of atual url the query parameter category
    if (route.name != "Home") {
      router.push({
        path: "/",
        query: { category: category[0], label: category[1] },
      });
      return;
    }
    if(selectedPage.value === 0) selectedPage.value = 1;

    // store in the url the filter
    router.push({
      query: { category: category[0], label: category[1], page: selectedPage.value },
    });

    store.dispatch("GET_POST_LIST_BY_CATEGORY", {
      category,
      page: selectedPage.value,
      size: DEFAULT_PAGE_SIZE,
    });
  }
  function getSelectedCategoryOnURL(){
    return new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams: any, prop: any) => searchParams.get(prop),
    });
  }
  function redoFilter() {
    const params = getSelectedCategoryOnURL();
    if (params && params.category != null) {
      const { category, label, page } = params as any;
      selectedPage.value = Number(page);
      store.dispatch("GET_POST_LIST_BY_CATEGORY", {
        category: [category, label],
        page: selectedPage.value === 0 ? 1 : selectedPage.value,
        size: DEFAULT_PAGE_SIZE,
      });
    }
  }
  return {
    page: selectedPage,
    changePage,
    doFilter,
    redoFilter,
    setNewFilter,
  };
}