<template>
    <div class="loginArea">

        <div class="container">

           <div class="close">
               <a href="" @click.prevent="closeRegister" >
                <font-awesome-icon :icon="['fa', 'close']" />
            </a>
           </div>

           <div class="loginBody">

               <h5>Cadastro</h5>

                <span  v-for="(item, index) in errorLogin" :key="index" class="errorMessage">
                   {{item.element}}
                </span>
               <div class="inputGroupDisplay">

                    <div class="inputGoup">
                        <span><font-awesome-icon :icon="['fa', 'user']" /></span>
                        <input v-model="userData.name" type="text" placeholder="Nome" class="inputRestritation">
                    </div>

                    <div class="inputGoup">
                        <span><font-awesome-icon :icon="['fa', 'user']" /></span>
                        <input type="text" v-model="userData.cpf" placeholder="CPF" class="inputRestritation">
                    </div>

                    <div class="inputGoup">
                        <span><font-awesome-icon :icon="['fa', 'user']" /></span>
                        <input type="text" v-model="userData.email" placeholder="Email" class="inputRestritation">
                    </div>

                    <div class="inputGoup">
                        <span><font-awesome-icon :icon="['fa', 'user']" /></span>
                        <select name="" v-model="userData.genre" class="inputRestritation" id="">
                            <option value="1">Masculino</option>
                            <option value="2">Femenino</option>
                        </select>
                        <!--<input type="text"  placeholder="Genero" class="inputRestritation">-->
                    </div>

                        <div class="inputGoup">
                        <span><font-awesome-icon :icon="['fa', 'user']" /></span>
                        <input type="password" v-model="userData.password" placeholder="Senha" class="inputRestritation">
                    </div>

                    <div class="inputGoup">
                        <span><font-awesome-icon :icon="['fa', 'user']" /></span>
                        <input type="password" placeholder="Confrime sua senha" v-model="userData.passwordConfirm" class="inputRestritation">
                    </div>

               </div>


               <a href="" @click.prevent="userRigster" class="buttonRegistration">
                   <span>Cadastrar</span>
               </a>

               <a href="" @click.prevent="openLogin" class="recoverPassword">Já tem um acesso? Faça seu <span> Login</span></a>

           </div>

        </div>

    </div>
</template>

<script  >

import {useStore} from "vuex"
import {reactive} from "vue"


    export default{
        name: 'authRegister',
        setup(){
            const store = useStore()
            let errorLogin = reactive([])
            const closeRegister = ()=>{

                store.dispatch('CLOSE_REGISTER', false)

            }

            const openLogin = ()=>{

                store.dispatch('OPEN_LOGIN', true)

            }

            const userData = reactive({
                name: "",
                cpf: "",
                email: "",
                genre: "1",
                password: "",
                passwordConfirm: ""
            })

            const userRigster = async ()=>{
            
                const response = await store.dispatch('REGISTER_USER', userData)
                
                if(response.isSuccess){
                    store.dispatch('OPEN_LOGIN', true)
                }

                errorLogin.pop()
                console.log(response.data.erros)
                if(response.data.erros){

                    response.data.erros.forEach(element => {
                        errorLogin.push({element})
                    });

                }

                /*if(response.data.erros){

                    errorLogin = response.data.erros
                }*/

            }
            return {userRigster, openLogin, closeRegister,userData, errorLogin}
        }
    }

</script>

<style lang="scss" scoped>

@import '../assets/scss/register.scss';

</style>