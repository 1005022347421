import { ref, watchEffect } from "vue";

export function useMobileVersion(){
    const isMobile = ref(false);
    const width = ref(window.innerWidth);
    const handleResize = () => {
      width.value = window.innerWidth;
        if (window.innerWidth < 600) {
          isMobile.value = true;
        } else {
          isMobile.value = false;
        }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return {isMobile, width};
}